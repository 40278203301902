var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-10",attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"width":"96%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-row',{staticClass:"pa-10 pt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-10",attrs:{"elevation":"0"}},[_c('v-toolbar',{staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"0","height":"80px"}},[_c('div',[_c('p',{staticClass:"mb-0 text-h4 font-weight-bold"},[_vm._v("All Orders")])]),_c('v-spacer'),_c('v-menu',{attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-5",staticStyle:{"width":"50px"},attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{staticClass:"mt-0",attrs:{"prev-icon":"mdi-skip-previous","next-icon":"mdi-skip-next","color":"purple darken-2"},on:{"input":_vm.onStartDateChange},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1),_c('v-menu',{attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-5",staticStyle:{"width":"50px"},attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{staticClass:"mt-0",attrs:{"min":_vm.start_date,"prev-icon":"mdi-skip-previous","next-icon":"mdi-skip-next","color":"purple darken-2"},on:{"input":_vm.onEndDateChange},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsWithIndex,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.date).toDateString())+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"purple darken-2","dark":""},on:{"click":function($event){return _vm.onDetails(item)}}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}},{key:"footer",fn:function(){return [_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}}),_c('v-col',[_c('v-card',{staticClass:"mx-2"},[_c('v-card-text',[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Total Profit : "+_vm._s(_vm.reportInfo.total_profit_from_orders)+" ")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Total Expense : "+_vm._s(_vm.reportInfo.total_expenses)+" "),_c('v-btn',{attrs:{"icon":"","color":"purple darken-2","dark":""},on:{"click":_vm.onExpenseDetails}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1),_c('hr'),_c('p',{staticClass:"font-weight-bold mt-2"},[_vm._v(" Net Profit : "+_vm._s(_vm.reportInfo.net_profit)+" ")])])],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }