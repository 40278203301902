<template>
  <v-container fluid class="mb-10">
    <v-row style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-row class="pa-10 pt-5">
            <v-col cols="12">
              <v-card class="mb-10" elevation="0">
                <v-toolbar
                  elevation="0"
                  style="background: #f5f5f5"
                  height="80px"
                >
                  <div>
                    <p class="mb-0 text-h4 font-weight-bold">All Orders</p>
                  </div>
                  <v-spacer></v-spacer>

                  <v-menu
                    v-model="menu1"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="start_date"
                        label="Start Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        class="pt-5"
                        style="width: 50px"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="start_date"
                      @input="onStartDateChange"
                      prev-icon="mdi-skip-previous"
                      next-icon="mdi-skip-next"
                      class="mt-0"
                      color="purple darken-2"
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    v-model="menu2"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="end_date"
                        label="End Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        class="pt-5"
                        style="width: 50px"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="end_date"
                      :min="start_date"
                      @input="onEndDateChange"
                      prev-icon="mdi-skip-previous"
                      next-icon="mdi-skip-next"
                      class="mt-0"
                      color="purple darken-2"
                    ></v-date-picker>
                  </v-menu>
                </v-toolbar>

                <v-card-text class="pa-0">
                  <v-row>
                    <!-- <v-col>
                      <v-card>
                        <v-toolbar color="purple" flat dark> Credit </v-toolbar>
                        <v-card-text style="overflow-y: auto; height: 60vh">
                          <v-col cols="12" v-for="n in 20" :key="n">
                            <v-card class="my-2 pa-5">
                              <v-row>
                                <v-col>
                                  <div class="font-weight-bold text-h4 d-flex">
                                    Order ID
                                    <v-spacer></v-spacer>
                                    :
                                  </div>
                                </v-col>
                                <v-col>
                                  <div class="font-weight-bold text-h4">
                                    3467
                                  </div>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <div class="font-weight-bold text-h4 d-flex">
                                    Profit <v-spacer></v-spacer>
                                    :
                                  </div>
                                </v-col>
                                <v-col>
                                  <div class="font-weight-bold text-h4">
                                    3467
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col>
                      <v-card>
                        <v-toolbar color="purple" flat dark> Credit </v-toolbar>
                        <v-card-text style="overflow-y: auto; height: 60vh">
                          <v-col cols="12" v-for="n in 20" :key="n">
                            <v-card class="my-2 pa-5">
                              <v-row>
                                <v-col>
                                  <div class="font-weight-bold text-h4 d-flex">
                                    Expense Name
                                    <v-spacer></v-spacer>
                                    :
                                  </div>
                                </v-col>
                                <v-col>
                                  <div class="font-weight-bold text-h4">
                                    xyz
                                  </div>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <div class="font-weight-bold text-h4 d-flex">
                                    Expense Amount
                                    <v-spacer></v-spacer>
                                    :
                                  </div>
                                </v-col>
                                <v-col>
                                  <div class="font-weight-bold text-h4">
                                    3467
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col>
                      <v-card height="height: 90%" style="text-align:center">
                        <v-toolbar color="purple" flat dark>
                          Net Profit
                        </v-toolbar>
                        <v-card-text class="" style="height: 90%">
                          <div>
                            <p>Total Credit : 27000</p>
                          </div>
                          <div>
                            <p>Total Debit : 27000</p>
                          </div>
                          <hr>
                           <div>
                            <p>Net Profit : 54000</p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col> -->
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="itemsWithIndex"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        hide-default-footer
                        class="elevation-1"
                      >
                        <template v-slot:item.time="{ item }">
                          {{ new Date(item.date).toDateString() }}
                        </template>

                        <template v-slot:item.details="{ item }">
                          <v-btn
                            icon
                            color="purple darken-2"
                            dark
                            @click="onDetails(item)"
                          >
                            <v-icon> mdi-eye </v-icon>
                          </v-btn>
                        </template>

                        <template v-slot:footer>
                          <div>
                            <v-row>
                              <v-col cols="9"></v-col>
                              <v-col>
                                <v-card class="mx-2">
                                  <v-card-text>
                                    <p class="font-weight-bold">
                                      Total Profit :
                                      {{ reportInfo.total_profit_from_orders }}
                                    </p>
                                    <p class="font-weight-bold">
                                      Total Expense :
                                      {{ reportInfo.total_expenses }}
                                      <v-btn
                                        icon
                                        color="purple darken-2"
                                        dark
                                        @click="onExpenseDetails"
                                      >
                                        <v-icon>mdi-open-in-new</v-icon>
                                      </v-btn>
                                    </p>
                                    <hr />
                                    <p class="font-weight-bold mt-2">
                                      Net Profit : {{ reportInfo.net_profit }}
                                    </p>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: true,

      menu1: false,
      menu2: false,
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),
      is_monthly: true,

      headers: [
        { text: "S/N", value: "index", sortable: false },
        { text: "Order ID", value: "order_id" },
        { text: "Profit", value: "profit" },
        { text: "Date", value: "time" },
        { text: "Details", value: "details", sortable: false },
      ],
      reportInfo: {
        orders: [],
      },
    };
  },
  computed: {
    itemsWithIndex() {
      return this.reportInfo.orders.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  methods: {
    // GET the report
    getReport() {
      this.loading = true;
      axios
        .post(`productdetails/get_report/`, {
          start_date: this.start_date,
          end_date: this.end_date,
          is_monthly: this.is_monthly,
        })
        .then((response) => {
          if (response.data.success) {
            this.reportInfo.orders = response.data.order_info;
            this.reportInfo.net_profit = response.data.net_profit;
            this.reportInfo.total_expenses = response.data.total_expenses;
            this.reportInfo.total_profit_from_orders =
              response.data.total_profit_from_orders;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // If start date changed , end date changed to empty
    onStartDateChange() {
      this.menu1 = false;
      this.end_date = "";
    },

    // If end date changed , call the API
    onEndDateChange() {
      // Make API call
      this.getReport();
      this.menu2 = false;
    },

    // Order details page
    onDetails(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/expense-report/order?order_id=${item.order_id}`,
      });
      routeData.href = `/dashboard/pages/expense-report/order?order_id=${item.order_id}`;
      window.open(routeData.href, "_blank");
    },

    // Expense details
    onExpenseDetails() {
      if (this.start_date && this.start_date) {
        let routeData = this.$router.resolve({
          name: `/dashboard/pages/expense-report/expense?start_date=${this.start_date}&end_date=${this.end_date}&is_monthly=${this.is_monthly}`,
        });
        routeData.href = `/dashboard/pages/expense-report/expense?start_date=${this.start_date}&end_date=${this.end_date}&is_monthly=${this.is_monthly}`;
        window.open(routeData.href, "_blank");
      }
    },
  },
  created() {
    this.getReport();
  },
};
</script>


<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>